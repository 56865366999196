@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

body {
  font-family: "Raleway", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #333;
  color: #fff;
}

.App {
  display: flex;
  flex-wrap: nowrap;
}

.sidemenu {
  flex: 0 0 270px;
  background-color: #444;
  padding: 20px;
}

.sidemenu h2 {
  margin-top: 0;
  color: #fff;
  display: flex;
  align-items: center;
}

.sidemenu nav {
  margin-top: 10px;
}

.sidemenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidemenu li {
  margin-bottom: 10px;
}

.sidemenu a {
  color: #ccc;
}

.sidemenu a:hover {
  color: #fff;
}

span {
  color: #e642c8;
  margin-right: 10px;
}

.mainpage {
  flex: 1;
  padding: 20px;
  background-color: #444;
  width: 80%;
}

.mainpage header {
  text-align: center;
}

.mainpage h1 {
  color: #fff;
}

.mainpage section {
  margin-bottom: 20px;
}

.advise {
  font-size: larger;
}

.userinfo,
.advisor,
.advise {
  padding: 20px;
  background-color: #555;
  transition: background-color 1s ease;
  transition: box-shadow 0.5s ease;
  transition: transform 0.5s ease;
}

.userinfo:hover,
.advisor:hover,
.advise:hover {
  background-color: #666;
  transform: scale(1.01);
  box-shadow: 1px, 1px, 10px, 2px rgba(228, 225, 225, 0.5);
}

.advisor form {
  margin-bottom: 10px;
}

.advisor label {
  display: block;
  margin-bottom: 5px;
  color: #fff;
}

.advisor select {
  background-color: #555;
  color: #fff;
  border: none;
}

.userinfo select {
  background-color: #555;
  color: #fff;
  border: none;
}

.button {
  background-color: #555;
  color: #fff;
  border: none;
  padding: 10px 20px;
}

.button:hover {
  background-color: #666;
}

.disclaimer {
  font-size: smaller;
  padding: 20px;
}

form {
  background-color: #333333;
  color: #f2f2f2;
  border: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}

.form-box {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 columns */
  grid-gap: 25px; /* space between the cells */
  margin: 20px;
  margin-bottom: 0;
}

.test-item-box {
  border-top: 1px dotted rgba(250, 250, 252, 0.909);
}

label {
  width: 200px;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}

input {
  width: 200px;
  height: 35px;
  padding: 0 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px #ccc;
  background-color: #555;
  color: #fff;
  font-weight: bold;
  font-size: larger;
}

select {
  width: 200px;
  height: 35px;
  padding: 0 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px #ccc;
  font-weight: bold;
  font-size: larger;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

option {
  font-size: 16px;
  line-height: 1.5;
  padding: 5px;
}

button[type="submit"] {
  width: 100px;
  height: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #555;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #666;
}

@media (min-width: 600px) and (max-width: 800px) {
  .App {
    flex-wrap: wrap; /* allow the elements to wrap to the next line */
  }
  .sidemenu {
    flex: 1;
    /* take up full width of the container */
  }
  .mainpage {
    width: 100%; /* take up full width of the container */
  }
  input {
    width: 150px;
    height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px #ccc;
    background-color: #555;
    color: #fff;
    font-weight: bold;
    font-size: medium;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .App {
    flex-wrap: wrap; /* allow the elements to wrap to the next line */
  }
  .sidemenu {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5rem;
    /* take up full width of the container */
  }

  h2 ul {
    display: flex;
    text-align: center;
    justify-content: space-between;
  }
  .mainpage {
    width: 100%; /* take up full width of the container */
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 3 columns */
    grid-gap: 5px; /* space between the cells */
    margin: 5px;
    margin-bottom: 0;
  }

  label {
    width: 200px;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    margin: 5px;
  }

  input {
    width: 150px;
    height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px #ccc;
    background-color: #555;
    color: #fff;
    font-weight: bold;
    font-size: medium;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  select {
    width: 80%;
    height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px #ccc;
    font-weight: bold;
    font-size: medium;
    margin: 5px;
  }

  option {
    font-size: 18px;
    line-height: 2;
    padding: 10px;
  }

  .form-box {
    margin: 5px 0;
    display: flex;
    align-items: center;
  }

  button[type="submit"] {
    width: 90px;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.8rem;
    cursor: pointer;
  }
}
